import React from "react";
import { Link } from "react-router-dom";

//Component
import ClientsSlider from "../components/Home/ClientsSlider";
import NewsLetter from "../components/NewsLetter";

//Images
import book16 from "./../assets/images/books/grid/book16.jpg";
import book3 from "./../assets/images/books/grid/book3.jpg";
import book5 from "./../assets/images/books/grid/book5.jpg";
import book12 from "./../assets/images/books/grid/book12.jpg";
import book8 from "./../assets/images/books/grid/book8.jpg";
import book14 from "./../assets/images/books/grid/book14.jpg";
import book15 from "./../assets/images/books/grid/book15.jpg";
import book4 from "./../assets/images/books/grid/book4.jpg";
import book9 from "./../assets/images/books/grid/book9.jpg";
import book2 from "./../assets/images/books/grid/book2.jpg";
import book7 from "./../assets/images/books/grid/book7.jpg";
import book13 from "./../assets/images/books/grid/book13.jpg";
import book10 from "./../assets/images/books/grid/book10.jpg";
import book1 from "./../assets/images/books/grid/book1.jpg";
import book6 from "./../assets/images/books/grid/book6.jpg";
import book17 from "./../assets/images/books/grid/book17.jpg";
import book18 from "./../assets/images/books/grid/book18.jpg";
import book19 from "./../assets/images/books/grid/book19.jpg";

const cardDetials = [
  {
    image: book1,
    title: "Valves",
  },
  {
    image: book2,
    title: "Guages",
  },
  {
    image: book3,
    title: "Silencer",
  },
  {
    image: book4,
    title: "Coil & Connectors",
  },
  {
    image: book6,
    title: "DP Flow Meter",
  },
  {
    image: book7,
    title: "Pneumatic Cylinder",
  },
  {
    image: book8,
    title: "Measuring Instrument",
  },
  {
    image: book9,
    title: "Absorbant Pads",
  },
  {
    image: book10,
    title: "Tools",
  },
  {
    image: book12,
    title: "Filter",
  },
  {
    image: book13,
    title: "Pneumatic Fittings",
  },
  {
    image: book14,
    title: "Transmitters",
  },
  {
    image: book15,
    title: "Solenoid Valve",
  },
  {
    image: book16,
    title: "Repair Kit",
  },
  {
    image: book17,
    title: "Mechanical Seals",
  },
  {
    image: book18,
    title: "PPE",
  },
  {
    image: book19,
    title: "Sensors",
  },
];

function BooksGridView() {
  return (
    <>
      <div className="page-content bg-grey">
        <section className="content-inner-1 border-bottom">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="title">Products</h4>
            </div>

            <div className="row book-grid-row">
              {cardDetials.map((data, i) => (
                <div className="col-book style-1" key={i}>
                  <div className="dz-shop-card style-1">
                    <div className="dz-media">
                      <img src={data.image} alt="book" />
                    </div>
                    <div className="dz-content">
                      <h5 className="title">
                        <a>{data.title}</a>
                      </h5>

                      <div className="book-footer">
                        <Link
                          to={"contact-us"}
                          className="btn btn-secondary box-btn btnhover btnhover2"
                        >
                          <i className="flaticon-shopping-cart-1 m-r10"></i> Get
                          qoute
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <div className="bg-white py-5">
          <div className="container">
            <ClientsSlider />
          </div>
        </div>
        <NewsLetter />
      </div>
    </>
  );
}
export default BooksGridView;
