import { BrowserRouter, Route, Switch } from "react-router-dom";
import ScrollToTop2 from "react-scroll-to-top";

//layouts
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";

//Pages
import Home from "./Home";
import BooksGridView from "./BooksGridView";
import ContactUs from "./ContactUs";
import AboutUs from "./AboutUs";
import ScrollToTop from "./../layouts/ScrollToTop";

//images
import logo from "./../assets/images/logo.png";

function Index() {
  return (
    <BrowserRouter>
      <div>
        <Header />
        {/* <Main> */}
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/products" exact component={BooksGridView} />
          <Route path="/contact-us" exact component={ContactUs} />
          <Route path="/about-us" exact component={AboutUs} />
          {/* <Route
            path="/*"
            component={() => {
              window.location.replace("https://google.com");
              return null;
            }}
          /> */}
        </Switch>
        {/* </Main> */}
        <Footer logoImage={logo} />
        <ScrollToTop />
        <ScrollToTop2
          className="styles_scroll-to-top__2A70v  fas fa-arrow-up scroltop"
          smooth
        />
      </div>
    </BrowserRouter>
  );
}

export default Index;
