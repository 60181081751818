import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Collapse from "react-bootstrap/Collapse";
import { MenuListArray2 } from "./MenuListArray2";

import logo from "./../assets/images/logo.png";

function Header() {
  /* for sticky header */
  const [headerFix, setheaderFix] = React.useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  /* for open menu Toggle btn  */
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const showSidebar = () => setSidebarOpen(!sidebarOpen);
  /*  Toggle btn End  */

  useEffect(() => {
    var mainMenu = document.getElementById("OpenMenu");
    if (mainMenu) {
      if (sidebarOpen) {
        mainMenu.classList.add("show");
      } else {
        mainMenu.classList.remove("show");
      }
    }
  });

  // Menu dropdown list
  const [active, setActive] = useState("Home");
  const handleMenuActive = (status) => {
    setActive(status);
    if (active === status) {
      setActive("");
    }
  };
  // Menu dropdown list End
  return (
    <header className="site-header mo-left header style-1">
      <div
        className={`sticky-header main-bar-wraper navbar-expand-lg ${
          headerFix ? "is-fixed" : ""
        }`}
      >
        <div className="main-bar clearfix">
          <div className="container clearfix">
            {/* <!-- Website Logo --> */}
            <div className="logo-header logo-dark">
              <Link to={"/"}>
                <img src={logo} alt="logo" />
              </Link>
            </div>

            {/* <!-- Nav Toggle Button --> */}
            <button
              className={`navbar-toggler collapsed navicon justify-content-end ${
                sidebarOpen ? "open" : ""
              }`}
              onClick={showSidebar}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>

            {/* <!-- EXTRA NAV --> */}
            <div className="extra-nav">
              <div className="extra-cell">
                <Link
                  to={"contact-us"}
                  className="btn btn-primary btnhover b-update"
                >
                  Get In Touch
                </Link>
              </div>
            </div>

            {/* <!-- Main Nav --> */}
            <div
              className={`header-nav navbar-collapse collapse justify-content-start ${
                sidebarOpen ? "show" : ""
              }`}
              id="navbarNavDropdown"
            >
              <div className="logo-header logo-dark">
                <Link to={"/"}>
                  <img src={logo} alt="logo-bnt" />
                </Link>
              </div>

              <ul className="nav navbar-nav">
                {MenuListArray2.map((data, index) => {
                  return (
                    <li
                      key={index}
                      className={`${
                        active === data.title && data.content
                          ? "sub-menu-down open"
                          : data.content
                          ? "sub-menu-down"
                          : ""
                      } `}
                    >
                      <Link
                        to={data.content ? "#" : data.to}
                        onClick={() => handleMenuActive(data.title)}
                      >
                        <span>{data.title}</span>
                      </Link>
                      {data.content && (
                        <Collapse in={active === data.title ? true : false}>
                          <ul className="sub-menu">
                            {data.content &&
                              data.content.map((data, index) => {
                                return (
                                  <li key={index}>
                                    <Link to={data.to}>{data.title}</Link>
                                  </li>
                                );
                              })}
                          </ul>
                        </Collapse>
                      )}
                    </li>
                  );
                })}
              </ul>
              <div className="dz-social-icon">
                <ul>
                  <li>
                    <a
                      className="fab fa-facebook-f"
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/bournetechnicals/"
                    ></a>
                  </li>
                  <li>
                    <a className="fab fa-twitter"></a>
                  </li>
                  <li>
                    <a className="fab fa-linkedin-in"></a>
                  </li>
                  <li>
                    <a className="fab fa-instagram"></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
