import React from "react";

import blog1 from "./../assets/images/blog/large/oilgas.jpg";
import blog2 from "./../assets/images/blog/large/powerplant.jpg";
import blog3 from "./../assets/images/blog/large/water.jpg";
import blog4 from "./../assets/images/blog/large/cooling.jpg";
import blog5 from "./../assets/images/blog/large/steel.jpg";
import blog6 from "./../assets/images/blog/large/food.jpg";
import blog8 from "./../assets/images/blog/large/NUCLEAR.jpg";
import blog9 from "./../assets/images/blog/large/SOLAR.jpg";
import blog10 from "./../assets/images/blog/large/PAPER.jpg";
import blog11 from "./../assets/images/blog/large/AVIATION.jpg";
import blog12 from "./../assets/images/blog/large/cement.jpg";
import blog13 from "./../assets/images/blog/large/OEMs.jpg";

const blogData = [
  {
    image: blog1,
    title: "OIL & GAS",
  },
  {
    image: blog2,
    title: "POWER-PLANTS & DESALINATION",
  },
  {
    image: blog3,
    title: "WATER & WASTE WATER",
  },
  {
    image: blog4,
    title: "DISTRICT COOLING PLANTS – DCP & HVAC",
  },
  ,
  {
    image: blog5,
    title: "STEEL | ALUMINIUM | MINING",
  },
  {
    image: blog6,
    title: "FOOD & BEVERAGE | PHARMACEUTICALS",
  },
  {
    image: blog8,
    title: "NUCLEAR ENERGY",
  },
  {
    image: blog9,
    title: "SOLAR | GREEN ENERGY",
  },
  {
    image: blog10,
    title: "PAPER | PLASTIC",
  },
  {
    image: blog11,
    title: "AVIATION",
  },
  {
    image: blog12,
    title: "CEMENT",
  },
  {
    image: blog13,
    title: "OEMs & MANUFACTURERS",
  },
];

const BlogGrid = () => {
  return (
    <>
      <div className="page-content">
        <section className="content-inner-1 bg-img-fix">
          <div className="section-head text-center w-serve">
            <h2 className="title">Industries Served</h2>
            <p>
              Bourne technicals with an experienced team, qualified engineers
              and professionals, have fully geared to develop into one of the
              most premium class suppliers of Industrial, building and
              constructional products import and exports worldwide.
            </p>
          </div>
          <div className="container">
            <div className="row">
              {blogData.map((item, ind) => (
                <div className="col-xl-4 col-lg-6" key={ind}>
                  <div className="dz-blog style-1 bg-white m-b30">
                    <div className="dz-media dz-img-effect zoom">
                      <img src={item.image} alt="" />
                    </div>
                    <div className="dz-info">
                      <h4 className="dz-title d-font">
                        <a>{item.title}</a>
                      </h4>
                      <p className="m-b0">{item.p}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default BlogGrid;
