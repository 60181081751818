import React from "react";
import ClientsSlider from "../components/Home/ClientsSlider";

import HomeMainSlider from "../components/Home/HomeMainSlider";
import NewsLetter from "../components/NewsLetter";
import BlogGrid from "./BlogGrid";

function Home() {
  return (
    <>
      <div className="page-content bg-white">
        <div className="main-slider style-1">
          <HomeMainSlider />
        </div>
        <section className="content-inner-1 bg-grey reccomend">
          <div className="container">
            <div className="section-head text-center">
              <h2 className="title">Our Brands</h2>
              <p>
                Companies around the world use bourne technicals to build
                stronger relationship with thier customers and change the way
                they do business.
              </p>
            </div>
            <ClientsSlider />
          </div>
        </section>
        <div>
          <BlogGrid />
        </div>
        <NewsLetter />
      </div>
    </>
  );
}

export default Home;
