import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

//Images
import client1 from "./../../assets/images/client/client1.png";
import client2 from "./../../assets/images/client/client2.png";
import client3 from "./../../assets/images/client/client3.png";
import client4 from "./../../assets/images/client/client4.png";
import client5 from "./../../assets/images/client/client5.png";
import client6 from "./../../assets/images/client/client6.png";
import client7 from "./../../assets/images/client/client7.png";
import client8 from "./../../assets/images/client/client8.png";
import client9 from "./../../assets/images/client/client9.png";
import client10 from "./../../assets/images/client/client10.png";
import client11 from "./../../assets/images/client/client11.png";
import client12 from "./../../assets/images/client/client12.png";

// import Swiper core and required modules
import { Autoplay } from "swiper";

//SwiperCore.use([EffectCoverflow,Pagination]);

const clientData = [
  { image: client1 },
  { image: client2 },
  { image: client3 },
  { image: client4 },
  { image: client5 },
  { image: client6 },
  { image: client7 },
  { image: client8 },
  { image: client9 },
  { image: client10 },
  { image: client11 },
  { image: client12 },
];

export default function ClientsSlider() {
  return (
    <>
      <Swiper
        className="swiper-container client-swiper"
        speed={2000}
        parallax={true}
        slidesPerView={3}
        spaceBetween={30}
        // loop={true}
        autoplay={{
          delay: 2000,
        }}
        modules={[Autoplay]}
        // breakpoints={{
        //   1600: {
        //     slidesPerView: 5,
        //   },
        //   1400: {
        //     slidesPerView: 4,
        //   },
        //   1200: {
        //     slidesPerView: 3,
        //   },
        //   991: {
        //     slidesPerView: 3,
        //   },
        //   591: {
        //     slidesPerView: 2,
        //   },
        //   320: {
        //     slidesPerView: 2,
        //   },
        // }}
      >
        {clientData.map((d, i) => (
          <SwiperSlide key={i}>
            <img className="logo-main" src={d.image} alt="brand-dealing" />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
