import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";

//Components
import PageTitle from "./../layouts/PageTitle";
import ClientsSlider from "./../components/Home/ClientsSlider";
import NewsLetter from "../components/NewsLetter";

//Imgaes
import about1 from "./../assets/images/about/about1.jpg";
import about2 from "./../assets/images/about/about2.jpg";

const missionBlog = [
  {
    iconClass: "flaticon-open-book-1",
    title: "Solution",
    content:
      "We make our customers successful by offering specialist solutions.",
  },
  {
    iconClass: "flaticon-transfer",
    title: "Supply",
    content: "Provides on time delivery as committed strictly followed.",
  },
  {
    iconClass: "flaticon-user",
    title: "Manufacture",
    content: "Manufacture OEM products as per client’s requirement.",
  },
  {
    iconClass: "flaticon-compass-circular-tool",
    title: "Import / Export",
    content:
      "Strong relation with logistics team to ship whole over the world.",
  },
  {
    iconClass: "flaticon-check-mark",
    title: "Clearance",
    content: "Easy and Reliable to clear shipment with customs.",
  },
  {
    iconClass: "flaticon-phone",
    title: "24 x 7 Supports",
    content: "Technical support with cost saving and ease of purchase product.",
  },
];

function AboutUs() {
  return (
    <>
      <div className="page-content bg-white">
        <PageTitle parentPage="Home" childPage="About us" />
        <section className="content-inner overlay-white-middle">
          <div className="container">
            <div className="row about-style1 align-items-center">
              <div className="col-lg-6 m-b30">
                <div className="row sp10 about-thumb">
                  <div className="col-sm-6 aos-item">
                    <div className="split-box">
                      <div>
                        <img className="m-b30" src={about1} alt="img" />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="split-box ">
                      <div>
                        <img
                          className="m-b20 aos-item"
                          src={about2}
                          alt="img"
                        />
                      </div>
                    </div>
                    <div className="exp-bx aos-item">
                      <div className="exp-head">
                        <div className="counter-num">
                          <h2>
                            <span className="counter">
                              {" "}
                              <CountUp end={99} />
                            </span>
                            <small>+</small>
                          </h2>
                        </div>
                        <h6 className="title">suppliers With Us</h6>
                      </div>
                      <div className="exp-info">
                        <ul className="list-check primary">
                          <li>Oil & Gas</li>
                          <li>Power Energy</li>
                          <li>Automation & Control</li>
                          <li>General Trading & Sourcing</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 m-b30 aos-item">
                <div className="about-content px-lg-4">
                  <div className="section-head style-1">
                    <h2 className="title">Bourne Technicals</h2>
                    <p>
                      We are here to listen from you deliver exellence BOURNE
                      TECHNICALS with an experienced team, qualified engineers
                      and professionals, have fully geared to develop into one
                      of the most premium class suppliers of Industrial,
                      building and constructional products import and exports
                      worldwide
                    </p>
                  </div>
                  <Link
                    to={"contact-us"}
                    className="btn btn-primary btnhover shadow-primary"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-inner-1 bg-light">
          <div className="container">
            <div className="section-head text-center">
              <h2 className="title">Our Mission</h2>
              <p>
                We at BOURNE TECHNICALS are as ever exuberant to accelerate the
                momentum and to elevate the preset levels of commitment when
                catering to challenging and ever growing needs of Industrial and
                Constructional Projects by delivering the highest level of
                quality and technical excellence to customers with services that
                are absolutely satisfying.
              </p>
            </div>
            <div className="row">
              {missionBlog.map((data, i) => (
                <div className="col-lg-4 col-md-6" key={i}>
                  <div className="icon-bx-wraper style-3 m-b30">
                    <div className="icon-lg m-b20">
                      <i className={`icon-cell ${data.iconClass}`}></i>
                    </div>
                    <div className="icon-content">
                      <h4 className="title">{data.title}</h4>
                      <p>{data.content}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <div className="py-5">
          <div className="container">
            <ClientsSlider />
          </div>
        </div>
        <NewsLetter />
      </div>
    </>
  );
}
export default AboutUs;
